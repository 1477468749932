import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import "~styles/pages/services/_content-pr.scss";
import Image from "../../components/base/image";
import TypeAnimation from "react-type-animation";

const ContentPage = () => (
  <Layout>
    <SEO
      title="Organic & Social Content Services"
      description="Pick your monthly package for content planning, creative direction, design/production, and scheduling for successful organic social campaigns. Simple. "
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={[
            "Organic Content",
            500,
            "Organic Content and Partnership Strategy",
            500,
          ]}
          className={"title"}
          wrapper="h1"
        />
        <div className="content-pr-header">
          {/* <h4>Work Samples</h4> */}
          <div className="sample-list">
            <Image
              filename="content-work-sample1.png"
              alt="Product Photography"
            />
            <Image
              filename="content-work-sample2.jpg"
              alt="Product Photography"
            />
            <Image
              filename="content-work-sample3.jpg"
              alt="Product Photography"
            />{" "}
            <Image
              filename="content-work-sample4.jpg"
              alt="Product Photography"
            />
          </div>
          <p>
            Included in all options is the content planning, creative direction,
            design/production, and scheduling for successful organic social
            campaigns. This is inclusive of the creative thinking, project
            management, research, copywriting, graphic design, creative skills,
            content scheduling and reporting needed to complete the
            deliverables.
          </p>
        </div>
      </div>
    </section>
    <section className="">
      <div className="container">
        <Products>
          <div
            title="Small Content Package"
            price="$1,500 CAD per month"
            subtitle="$1,500 CAD per month"
            extrainfo="+ one-time $625 CAD launch fee"
            id="small-content-package"
          >
            <p>
              The foundational content your brand needs to connect with your
              audience, deliver your key brand messages and nurture brand
              loyalty. This option is ideal for brands that want to grow at a
              slow and steady pace.
            </p>
          </div>
          <div
            title="Medium Content Package"
            price="$2,550 CAD per month"
            subtitle="$2,550 CAD per month"
            extrainfo="+ one-time $625 CAD launch fee"
            id="medium-content-package"
          >
            <p>
              The content your brand needs to create meaningful engagement with
              your audience, increase your chances of being found, and reach new
              audience segments. This option is ideal for brands that want to
              soft-launch a new venture or increase the growth rate of their
              social following & engagement.
            </p>
          </div>
          <div
            title="Large Content Package"
            price="$3,875 CAD per month"
            subtitle="$3,875 CAD per month"
            extrainfo="+ one-time $625 CAD launch fee"
            id="large-content-package"
          >
            <p>
              The content tools your brand needs to take your social growth to
              the next level and turn your organic social channels into powerful
              sales and brand loyalty tools. This option is ideal for brands
              that are launching a new product or business or entering a new
              geographical area. This package is also perfect for brands that
              have seen signs of stagnation and are looking for new ways to
              revitalize their following.
            </p>
          </div>
        </Products>
      </div>
    </section>
  </Layout>
);

export default ContentPage;
